export const Data = {
  profile: {
    name: "Millicent  Onjoro",
    tagline: "Connecting people to purpose",
    occupation: "Events Planning and Marketing | MC and Events Moderator | Conversation Curator | Customer Experience Expert",
    location: "Nairobi, Kenya",
    email: "wanderwamwangi@gmail.com",
    telephone: "+254723157260",
    image: "images/millie-profile.jpeg",
  },
  aboutMe: {
    label: "Profile",
    description:
      `I love people and connecting them to a purpose through music and creating platforms for conversations. I also love organizing and coordinating events. 
      I am passionate about Leadership, Strategy Development, Mentorship and helping small businesses curate amazing Customer Experience journeys through advocacy and training.`,
  },
  skills: {
    technicalLabel: "Skills",
    softLabel: "Skills",
    technicalSkills: [],
    softSkills: [
      "Training and mentorship",
      "Events (Development, Planning, Coordination, Moderating/MCeeing)",
      "Leadership",
      "Product Management",
      "Ability to navigate ambiguity",
      "Honesty",
      "Integrity",
    ],
  },
  socialMedia: {
    label: "SOCIAL",
    social: [
      {
        label: "Millicent Wanderwa",
        name: "linkedin",
        url: "https://www.linkedin.com/in/millicent-wanderwa-a23b1079",
        className: "bxl-linkedin-square",
      },
      {
        label: "Millie Onjoro",
        name: "facebook",
        url: "https://www.facebook.com/mamaAimah",
        className: "bxl-facebook",
      },
      {
        label: "millie_onjoro",
        name: "instagram",
        url: "https://www.instagram.com/millie_onjoro",
        className: "bxl-instagram",
      },
      {
        label: "@millie_wanderwa",
        name: "twitter",
        url: "https://twitter.com/millie_wanderwa",
        className: "bxl-twitter",
      },
    ],
  },
  experience: {
    works: [
      {
        title: "Crecica",
        company: "Co-Founder",
        period: "August 2022 - Current",
        description: [
          "Program development",
          "Public relations",
        ],
      },
      {
        title: "Fairview Coffee Estate",
        company: "Head of Coffee Tourism",
        period: "June 2015 - September 2021",
        description: [
          "Strategy development", "Tour Guiding", "Hiring and training", "Budget development", 
          "Product development", "Events planning", "Administration", "Reporting", 
          "Coffee tour script development", "Public relations", 
          "Sourcing beneficial business partnerships",
        ],
      }
    ],
    academic: [
      {
        career: "BSc Hospitality Management",
        date: "2010 - 2014",
        institution: "Kenyatta University",
      },
    ],
    projects: [
      {
        name: "Innovator and lead planner for Kahawa Festival",
        company: "",
        period: "",
        description: [
          "An event that takes place every year in October to celebrate coffee and coffee culture in Kenya",
        ],
      },
      {
        name: "Sauti Ya Kahawa",
        company: "A Kenyan coffee platform",
        period: "",
        description: [
          "Served as Youth Representative",
        ],
      },
      {
        name: "Africa Finest Coffees Association",
        company: "",
        period: "",
        description: [
          "Served as Women Representative",
        ],
      },
    ]
  },
};
